
            import React from 'react';
            const mdx = (name, props, ...children) => {
                if (name === 'inlineCode') { name = 'code'; }
                delete props?.parentName;
                return React.createElement(name, props, ...children);
            }
        
/* @jsxRuntime classic */
/* @jsx mdx */
import { Stack } from "@fluentui/react";
import Head from 'next/head';
import { ExternalLink } from '../components';
import { RouteStackProps } from "../utils";


const layoutProps = {
  
};
const MDXLayout = ({children}) => (
    <div style={{ height: '100%', padding: '0 16px', overflow: 'auto' }}>
        <Head>
            <title>WebADB</title>
        </Head>
        {children}
    </div>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {
      /*
      cspell: ignore cybojenix
      */
    }
    <p>{`Welcome to WebADB! The best way to control and manage your android device using just the browser! Nothing to install or setup, just plug and play!`}</p>
    <h1>{`WebADB is sponsored by BobbysDeals!! Save money and live high for cheap by checking out the most amazing curated deals platform - `}<ExternalLink href="https://bobbysdeals.net" mdxType="ExternalLink"><a parentName="h1" {...{
          "href": "https://bobbysdeals.net"
        }}>{`https://bobbysdeals.net`}</a></ExternalLink></h1>
    <h2>{`Need Help?`}</h2>
    <p>{`We understand that webadb is a complex and powerful tool that can be overwhelming at times! Come join us on our `}<ExternalLink href="https://forums.apps4flip.com" mdxType="ExternalLink">{`forums`}</ExternalLink>{` where you can ask any questions that you have!`}</p>
    <h2>{`Compatibility`}</h2>
    <p>{`Currently, only Chromium-based browsers (Chrome, Microsoft Edge, Opera) support the WebUSB API.`}</p>
    <h2>{`FAQ`}</h2>
    <h3>{`Got "Unable to claim interface" error`}</h3>
    <p>{`One USB device can only be accessed by one application at a time. Please make sure:`}</p>
    <ol>
      <li parentName="ol">{`Official ADB server is not running (stop it by `}<inlineCode parentName="li">{`adb kill-server`}</inlineCode>{`).`}</li>
      <li parentName="ol">{`No other Android management tools are running.`}</li>
      <li parentName="ol">{`No other WebADB tabs have already connected to your device.`}</li>
    </ol>
    <h3>{`Credits`}</h3>
    <p>{`This website is based on but not affiliated with the MIT licenced project ya-webadb - `}<ExternalLink href="https://github.com/yume-chan/ya-webadb" mdxType="ExternalLink">{`here`}</ExternalLink>{`. We recommend contributing and donating to the author of that project if you want to support WebADB. All changes to that repository will eventually be upstreamed to this site.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;